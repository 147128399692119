

import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import '../../Style/about-section.css';
import aboutImg from '../../assets/all-images/1.png'

const AboutSection = ({aboutClass}) => {
  return (
   <section 
   className='about__section'
   style={
    aboutClass === 'aboutPage'? {marginTop: "0px"} : {marginTop: "280px"}
   }
   >
    <Container>
        <Row>
            <Col lg='6' md='6'>
                <div className="about__section-content">
                    <h4 className="section__subtitle">About Us</h4>
                    <h2 className="section__title">Welcome to car hire service</h2>
                    <p className="section__description">
                    Welcome to <b>Rent a Car</b>, your premier choice for hassle-free car rental services all across Pakistan. At <b>Rent a Car</b>, we specialize in providing top-notch rental solutions with professional drivers, ensuring a comfortable and convenient journey for our valued customers.
<br />
<h4>Our Commitment:</h4>

With a commitment to excellence and customer satisfaction, we strive to deliver unparalleled service standards in the car rental industry. Whether you're traveling for business or pleasure, we are dedicated to making your experience seamless and enjoyable.

<br />
<h4>
Wide Coverage:

</h4>
Operating throughout Pakistan, <b>Rent a Car</b> offers comprehensive coverage, ensuring that you can access our services wherever your journey takes you. From bustling cities to serene countryside, we've got you covered.

<br />

<h4>
Professional Drivers:
    
</h4>
Sit back, relax, and let our experienced drivers take the wheel. Our team of skilled professionals is committed to providing safe and reliable transportation, allowing you to focus on what matters most while on the road.

<br />
<h4>
Flexible Options:

</h4>
At <b>Rent a Car</b>, we understand that every journey is unique. That's why we offer a range of flexible options to suit your needs. Whether you require a one-way drop or a multi-day rental, we have the perfect solution for you.

<br />
<h4>
Quality Assurance:

</h4>
Your satisfaction is our priority. With a fleet of well-maintained vehicles and a team of dedicated professionals, we ensure that every aspect of your rental experience meets the highest standards of quality and reliability.

<br />
<h4>
Experience the Difference:

</h4>
Discover the difference with <b>Rent a Car</b>. Experience unmatched convenience, comfort, and reliability with our premium car rental services. Wherever you're headed, trust us to get you there safely and in style.

<br />
<br />
Contact us today to learn more about our services and book your next journey with <b>Rent a Car</b>. 

                   </p>
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>professional Drivers.
                        </p>
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>Neat & Clean Cars.
                        </p>

                    </div>
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>Pick & Drop Avaiable.
                        </p>
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>Any time Booking Avaiable.
                        </p>

                    </div>
                </div>
            </Col>

            <Col lg='6' md='6'>
                <div className="about__img">
                    <img src={"http://res.cloudinary.com/freedeveloperss123/image/upload/v1710153460/a0o5hzdhlw4zhhczlmyr.png"} alt="" className='w-100' />
                </div>
            </Col>
        </Row>
    </Container>
   </section>
  )
}

export default AboutSection