


import React from 'react'
import { Form, FormGroup } from 'reactstrap'
import '../../Style/find-car-form.css'

const FindCarForm = () => {
  return (
    
    <div className="form">
      <div className=" d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="form__group">
          <input type="text" placeholder="From address" required />
        </FormGroup>

        <FormGroup className="form__group">
          <input type="text" placeholder="To address" required />
        </FormGroup>

        <FormGroup className="form__group">
          <input type="date" placeholder="Journey date" required />
        </FormGroup>

        <FormGroup className="form__group">
          <input
            className="journey__time"
            type="time"
            placeholder="Journey time"
            required
          />
        </FormGroup>
        <FormGroup className="select__group">
          <select>
            <option value="ac">AC Car</option>
            <option value="non-ac">Non AC Car</option>
          </select>
        </FormGroup>

        <FormGroup className="form__group">
          <button className="btn find__car-btn"><a href="tel:+92 339 800 0011" style={{color:"white",textDecoration:"none"}}>Book Now</a></button>
        </FormGroup>
      </div>
    </div>
  )
}

export default FindCarForm