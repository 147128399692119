const serviceData = [
  {
    id: 1,
    title: "City Transfer",
    icon: "ri-map-pin-2-line",
    desc: "Your premier choice for seamless car rental solutions across Pakistan, ensuring a smooth journey from start to finish.",
  },

  {
    id: 2,
    title: "Whole  City Tour",
    icon: "ri-community-line",
    desc: "Embark on a comprehensive exploration of the city with our expert tour services, uncovering its hidden gems and iconic landmarks.    ",
  },

  {
    id: 3,
    title: "Unlimited Miles Car Rental",
    icon: "ri-roadster-line",
    desc: "Experience the freedom of the open road with unlimited mileage on your rental, allowing you to venture far and wide without constraints.    ",
  },

  {
    id: 4,
    title: "Fast & Easy Booking",
    icon: "ri-timer-flash-line",
    desc: "Streamline your travel plans with our quick and hassle-free booking process, ensuring a seamless experience from reservation to departure.    ",
  },

  {
    id: 5,
    title: "Many Pickup Locations",
    icon: "ri-map-pin-line",
    desc: "Conveniently choose from numerous pickup locations across the country, making it easy to start your journey from wherever you are.    ",
  },

  {
    id: 6,
    title: "Airport Transfer",
    icon: "ri-flight-takeoff-line",
    desc: "Start and end your trip with ease with our efficient airport transfer services, providing reliable transportation to and from the airport for a stress-free travel experience.    ",
  },
];

export default serviceData;
