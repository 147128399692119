import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import AboutSection from '../components/UI/AboutSection';
import FindCarForm from '../components/UI/FindCarForm';
import HeroSlider from '../components/UI/HeroSlider';
import ServiceList from '../components/UI/ServiceList';
import carData from '../assets/data/carData';
import CarItem from '../components/UI/CarItem';
import OneWay from './OneWay';
import BecomeDriverSection from '../components/UI/BecomeDriverSection';
import Testimonial from '../components/UI/Testimonial';
import BlogList from '../components/UI/BlogList';
import aboutImg from '../assets/all-images/1.png'



const Home = () => {
    return (
        <Helmet title='Home'>
            {/* ============ Hero Section ============== */}
        <section className="p-0 hero__slider-section">
            <HeroSlider/>
            <div className="hero__form">
                <Container>
                    <Row className='form__row'>
                        <Col lg='4' md='4'>
                            <div className="find__cars-left">
                                <h2>Find your best car here</h2>
                            </div>
                        
                        </Col>

                        <Col lg='8' md='8' sm='12'>
                            <FindCarForm/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>

        {/* ============ About Section ============== */}
        {/* <AboutSection/> */}
        <Container className="dvf">
        <Row>
            <Col lg='6' md='6'>
                <div className="about__section-content">
                    <h4 className="section__subtitle">About Us</h4>
                    <h2 className="section__title">Welcome to car hire service</h2>
                    <p className="section__description">
                    Welcome to <b>Rent a Car</b>, your premier choice for hassle-free car rental services all across Pakistan. At <b>Rent a Car</b>, we specialize in providing top-notch rental solutions with professional drivers, ensuring a comfortable and convenient journey for our valued customers.
<br />

<h4>
Professional Drivers:
    
</h4>
Sit back, relax, and let our experienced drivers take the wheel. Our team of skilled professionals is committed to providing safe and reliable transportation, allowing you to focus on what matters most while on the road.

<br />
<h4>
Flexible Options:

</h4>
At <b>Rent a Car</b>, we understand that every journey is unique. That's why we offer a range of flexible options to suit your needs. Whether you require a one-way drop or a multi-day rental, we have the perfect solution for you.

<br />
<h4>
Quality Assurance:

</h4>
Your satisfaction is our priority. With a fleet of well-maintained vehicles and a team of dedicated professionals, we ensure that every aspect of your rental experience meets the highest standards of quality and reliability.


<br />
<br />
Contact us today to learn more about our services and book your next journey with <b>Rent a Car</b>. 

                   </p>
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>professional Drivers.
                        </p>
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>Neat & Clean Cars.
                        </p>

                    </div>
                    <div className="about__section-item d-flex align-items-center">
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>Pick & Drop Avaiable.
                        </p>
                        <p className="section__description d-flex align-items-center    gap-2">
                        <i class="ri-checkbox-circle-line"></i>Any time Booking Avaiable.
                        </p>

                    </div>
                </div>
            </Col>

            <Col lg='6' md='6'>
                <div className="about__img">
                    <img src={"http://res.cloudinary.com/freedeveloperss123/image/upload/v1710153460/a0o5hzdhlw4zhhczlmyr.png"} alt="" className='w-100' />
                </div>
            </Col>
        </Row>
    </Container>
   
        {/* ============ Service Section ============== */}

            <section>
                <Container>
                    <Row>
                        <Col lg='12' className='mb-5 text-center'>
                            <h6 className="section__subtitle">See our</h6>
                            <h2 className="section__title">Popular Services</h2>
                        </Col>
                        <ServiceList/>
                    </Row>
                </Container>
            </section>

             {/* ============ Car Offer Section ============== */}
             <section>
                <Container>
                    <Row>
                        <Col lg='12' className='text-center mb-5'>
                            <h6 className="section__subtitle">Come with</h6>
                            <h2 className="section__title">Hot Offers</h2>
                        </Col>

                        {
                            carData.slice(0, 6).map((item) =>(
                                <CarItem item={item} key={item.id}/>
                            ))
                        }
                    </Row>
                </Container>
             </section>
                        <OneWay/>
              {/* ============ Become a Driver Section ============== */}
              <BecomeDriverSection/>

               {/* ============ Testimonial Section ============== */}

            

               <div className="container banner-group-1">
               
               <div className='container' style={{background :  ""}}>
                 <br/>
            
            <h2 class="title mb-4 text-center"> <b>Our Offices</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                   
                   
                                 <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                               <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3> 
                   
                   
                              <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Karachi Customer Center
 
</h3>
       <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/>  <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel: +92 333 2221927"> +92 315 3800047</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                      <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /> <a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>

                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>     <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Lahore Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel:i+92 339 8000011">+92 339 8000011</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>  <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Islamabad Customer Center
 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel: +92 315 3800047"> +92 315 3800047</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                          <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a>
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>     <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Multan  Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel:i+92 340 2221927">+92 340 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>    <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Hyderabad  Customer Center
 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}> <i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/><a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel: +92 315 3800047"> +92 315 3800047</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>





                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                             <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3> 
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Sukkar Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel:i+92 339 8000011">+92 339 8000011</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       </div>
                   

                     
</div>
</div>   

                 {/* ============ Blog Section ============== */}

                 <section>
                <Container>
                    <Row>
                        <Col lg='12' className='mb-5 text-center'>
                        <h6 className="section__subtitle">Explore our blogs</h6>
                        <h2 className="section__title">Latest Blogs</h2>
                        </Col>
                        <BlogList/>
                    </Row>
                </Container>
               </section>
    </Helmet>
    )
};

export default Home;