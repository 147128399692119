


import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import CarListing from '../pages/CarListing';
import CarDetails from '../pages/CarDetails';
import Blog from '../pages/Blog';
import BlogDetails from '../pages/BlogDetails';
import NotFound from '../pages/NotFound';
import Contact from '../pages/Contact';
import SignUp from '../SignUp/SignUp';
import Login from '../Login/Login';
import Privacy from '../pages/Privacy';
import Term from '../pages/Term';
import OneWayDrop from '../pages/OneWay';
import CarrentalKarachi from '../pages/carrentalkarachi';
import RentacarKarachi from '../pages/rentacarkarachi';
import RentacarFaisalabad from '../pages/rentacarFaisalabad';
import RentacarGujranwala from '../pages/rentacarGujranwala';
import RentacarIslamabad from '../pages/rentacarIslamabad';
import RentacarLahore from '../pages/rentacarLahore';
import RentacarPeshawar from '../pages/rentacarPeshawar';
import RentacarLarkana from '../pages/rentacarLarkana';
import RentacarPakistan from '../pages/rentacarPakistan';
import RentacarMultan from '../pages/rentacarMultan';


const Routers = () => {
  return (
    <Routes>
        <Route path='/' element={<Navigate to = '/home'/>}></Route>
        <Route path='/home' element={<Home/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/cars' element={<CarListing/>}></Route>
        <Route path='/cars/:slug' element={<CarDetails/>}></Route>
        <Route path='/blogs' element={<Blog/>}></Route>
        <Route path='/blogs/:slug' element={<BlogDetails/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/signup' element={<SignUp/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/privacy' element={<Privacy/>}></Route>
        <Route path='/onewaydrop' element={<OneWayDrop/>}></Route>
        <Route path='/term' element={<Term/>}></Route>
        <Route  path='/car-rental-in-karachi-pakistan' element={<CarrentalKarachi/>} />
        <Route  path='/rent-a-car-in-karachi' element={<RentacarKarachi/>} />
          <Route  path='/rent-a-car-in-Faisalabad' element={<RentacarFaisalabad/>} />
          <Route  path='/rent-a-car-in-Gujranwala' element={<RentacarGujranwala/>} />
          <Route  path='/rent-a-car-in-Islamabad' element={<RentacarIslamabad/>} />
          <Route  path='/rent-a-car-in-Lahore' element={<RentacarLahore/>} />
          <Route  path='/rent-a-car-in-Multan' element={<RentacarMultan/>} />
          <Route  path='/rent-a-car-in-Peshawar' element={<RentacarPeshawar/>} />
         
          <Route  path='/rent-a-car-in-Larkana' element={<RentacarLarkana/>} />
          <Route  path='/rent-a-car-in-Pakistan' element={<RentacarPakistan/>} />
        <Route path='*' element={<NotFound/>}></Route>
    </Routes>
  )
}

export default Routers