import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import AboutSection from '../components/UI/AboutSection';
import CommonSection from '../components/UI/CommonSection';
import driveImg from '../assets/all-images/drive.jpg'
import BecomeDriverSection from '../../src/components/UI/BecomeDriverSection'
import OurMembers from '../components/UI/OurMembers';

const About = () => {
    return (
       <Helmet title="About">
        <CommonSection title="About Us"/>
        <AboutSection aboutClass='aboutPage'/>

        <section className="about__page-section">
            <Container>
                <Row>
                    <Col lg='6' md='6' sm='12'>
                        <div className="about__page-img">
                            <img src={"http://res.cloudinary.com/freedeveloperss123/image/upload/v1710153460/a0o5hzdhlw4zhhczlmyr.png"} alt="" className='w-100 rounded-3' />
                        </div>
                    </Col>

                    <Col lg='6' md='6' sm='12'>
                        <div className="about__page-content">
                            <h2 className="section__title">We Are Commited To Provide Safe Journey Solution</h2>
                            <p className="section__description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio quisquam culpa obcaecati reprehenderit a incidunt repellendus cupiditate deleniti, quidem ab eos id voluptatibus asperiores 
                            </p>
                            <p className="section__description">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio quisquam culpa obcaecati reprehenderit a incidunt repellendus cupiditate deleniti, quidem ab eos id voluptatibus asperiores 
                            </p>
                            <div className="d-flex align-items-center gap-3 mt-4">
                           <span className='fs-4'>
                           <i class="ri-phone-line"></i>
                           </span>
                           <div>
                            <h6 className="section__subtitle">Need Any Help?</h6>
                            <h4><a href="tel:+92 339 800 0011" style={{color:"black"}}> +92 339 800 0011</a></h4>
                           </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <BecomeDriverSection/>

        {/* <section>
            <Container>
                <Row>
                    <Col lg='12' className='mb-5 text-center'>
                        <h6 className="section__subtitle">Experts</h6>
                        <h2 className="section__title">Our Members</h2>
                    </Col>
                    <OurMembers></OurMembers>
                </Row>
            </Container>
        </section> */}
<br />
       </Helmet>
    );
};

export default About;