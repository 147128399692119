

import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import '../../Style/become-driver-section.css';
import driverImg from '../../assets/all-images/cars-img/toyota-offer-2.png'

const BecomeDriverSection = () => {
  return (
    <section className='become__driver'>
        <Container>
            <Row>
                <Col lg='6' md='6' sm='12' className='become__driver-img'>
                    <img src={driverImg} alt="" className='w-100'/>
                </Col>


                <Col lg='6' md='6' sm='12'>
                    <h2 className="section__title become__driver-title">
                        Do You Want to Earn With Us? So Don't Be Late
                    </h2>
                    <button className="btn become__driver-btn mt-4"> <a href="tel:+92 339 800 0011" style={{color : "black",textDecoration:"none"}}> Become a Driver</a></button>
                </Col>
            </Row>
        </Container>

    </section>
  )
}

export default BecomeDriverSection